@import 'general';
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.css);


.about-hi {
    font-size: 20px;
    color: black;
}

.social-media a {
    margin-right:10px;
    text-decoration: none;
    cursor: pointer;
}

.hi-name {
    font-family: var(--title-font);
    font-weight: 600;
    color: black;
}

.profile-img {
 
    width: 280px;
    border-radius: 5%;
    height: 320px;
    overflow: auto;
    object-fit:cover;
    
}

.profile-container {
    margin: 1% 8% 5% 3%;
    padding-left: 0%;

}

.about-part {
    display: flex;
    width: auto;
}

.profile-img2 {
    filter: brightness(1.3) contrast(0.9) grayscale(0.1);
}

.greyimg {
    filter: grayscale(0.2);
}

.self-intro {
    width: auto;
}

