@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

:root {
    --title-font: 'Lato',
        sans-serif;
    --content-font: 'Open Sans',
        sans-serif;
    --background-light: rgb(250, 250, 250);
}

* {
    font-family: var(--content-font);
}

.design-main-container {
    background-color: rgba(128, 128, 128, 0);
    height: 100%; 
}



.design-container{
    padding-left: 25%;
        padding-right: 25%;
        padding-bottom: 5%;
        padding-top: 0%;
        
}

.design-container-drawing {
    padding-left: 25%;
    padding-right: 25%;
    padding-bottom: 20%;
    padding-top: 5%;

}


.design-container-bubble {
    padding-left: 18%;
    padding-right: 18%;
    padding-bottom: 5%;
    padding-top: 0%;
    background-color: black;
    color:white;

}
.design-name {
    font-weight: 900;
    font-size:xx-large;
    width: 100%;
    line-height: 200%;
}

.design-header-img {
    width: 100%;
}
.design-process-img {
    width: 100%;
    border-radius: 0%;
}

.design-header-div {
    width:50%;
    display: block;
    margin: 5% 0% 5% 25%;
}

.design-header-div-pebble {
    width: 100%;
    display: block;
    margin: 5% 0% 5% 0%;
    
}

.design-header-img-pebble {
    width: 100%;
    border-radius: 0%;
}

.design-header-img-tama {
    width: 100%;
    border-radius: 0%;
    width: 49.6%;
    padding: 0.2%;
}



.design-sec-title {
    font-weight: 900;
    margin-top: 50px;
    font-size:x-large;

}



.design-process-text{
    margin-bottom: 30px;
}



.design-process-text p{
    margin-top:30px
}

.design-header-sec {
    padding: 5% 25% 5% 25%;
    background-color: rgba(0, 0, 0, 0.039);
}

.design-title-tag {
    font-weight: 600;
        font-size: medium;
}

.design-process-hor {
    border-radius: 0%;
    width: 50%;
    display: block;
}

.design-process-ver {
    border-radius: 0%;
    height: 380px;
    width: auto;

}

.two-image {
    display: flex;
}

.two-image-ver {
display: flex;
}

.backtohome {
    position: fixed;
    top:0;
    left:0;
    margin: 20px;
    padding: 10px;
    background-image: radial-gradient(circle,
                #fdd7f674,
                #ffd8e381,
                #ffdecf84,
                #ffeac382,
                #f4f7c77e,
                #f4f7c772);
    border-radius: 5%;
    opacity: 0.8;
    transition: 0.2s;
    visibility: visible;
}

.backtohome:hover {
    opacity: 1;
    transition: 0.2s;
}
.loader-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgba(255, 209, 229, 0.168);
    z-index: 1;
}

.lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ripple div {
    position: absolute;
    border: 4px solid #000000a3;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}

.bubble-video {
    margin-top:80px;
        padding-left:10%;
        padding-right:10%;
        height: 450px;
}

@keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }

    4.9% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }

    5% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }

    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}



@media (max-width:820px) {
    .design-container {
        padding-left: 8%;
            padding-right: 8%;
            padding-bottom: 5%;
            padding-top: 0%;
    }
        .design-container-drawing {
            padding-left: 8%;
            padding-right: 8%;
            padding-bottom: 20%;
            padding-top: 5%;
    
        }

        .design-header-sec {
            padding: 5% 8% 5% 8%;
           
        }

        .two-image {
            display: block;
        }

        .design-process-hor {
width: 100%;
        }
        .design-process-ver {
            height: auto;
            width: 100%;
        }
        .backtohome {
            visibility: hidden;
        }
        .design-header-div {
width: 80%;
margin: 5% 0% 5% 10%;
        }

}