@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

:root {
    --title-font: 'Lato',
        sans-serif;
    --content-font: 'Open Sans',
        sans-serif;
    --background-light:rgb(250, 250, 250);
}
 * {
font-family: var(--content-font);
color: rgba(0, 0, 0, 0.638);
 }

.title {
    font-family: var(--title-font);
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.888);
}

.body-part {
    margin-top: 80px;
}

a{
    text-decoration-line: underline;
    text-decoration-style: dotted;
    text-decoration-color: rgba(0, 0, 0, 0.441);
    font-weight: 500;
    text-underline-offset: 3px;
    text-decoration-thickness:1px;
    color: rgba(0, 0, 0, 0.888);
   
}
a:hover{
    text-decoration: none;
    color: rgba(0, 0, 0, 0.691);
}

img {
    border-radius: 5%;
}

.each-container {
    display: flex;
    padding-left: 15%;
    padding-right: 15%;
    padding-bottom: 5%;
    padding-top: 5%;
}

.each-container a:hover {
    background-color: rgba(255, 133, 182, 0.223);
    
}

b {
font-weight: 500;
color: black;
}

.smalltext {
    font-size: 12px;;

}

@media (max-width:820px) {
    .each-container {
        display: block;
    }
    #intern {
        margin-top:10%;
    }
    .edu-part {
        width: 100%;
    }
        .intern-part {
            width: 100%;
        }

 

    .footer-container {
        font-size: 12px;
    }

        .about-part {
            margin-left:0%;
        }

                .profile-container {
                    margin: 5% 0% 10% 25%;
                    width: 100%;
                    border-radius: 5%;
                
                }

                                .profile-img {
                                    margin: 0% 0% 0% 0%;
                                    height: auto;
                                    
                                    
                                }

    
}

@media (max-width:450px) {
    .menu {
        display: none;
    }

        .each-container {
            padding-left: 8%;
            padding-right: 8%;
        }

                .profile-container {
                    margin: 5% 0% 10% 2%;
                    width: 100%;
        
                }

                .edu-logobox {
                    display: none;
                    
                    
                   
                }

                

                

                
}

