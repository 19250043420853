@import 'general';

  
  .conference-column {
    flex: 0 0 10%; /* 调整宽度为总宽度的 10% */
  padding-right: 20px;
  text-align: left;

  }

  .short-conference-name {
    background-color: #fdd7f649;
    padding: 5px;
    border-radius: 5px;
  }

 .conference {
  font-size:14px;
  font-weight: 600;
  
 }

  .authors {
    font-size: 14px;
    font-weight: 600;
    color: #00000083;
  }

  .conference-details {
    font-size: 14px;
    font-weight: 600;
    color: #00000083;
  }
  
  .each-research {
    display: flex;
    flex-direction: row; /* 默认水平排列，图片在左，文字在右 */
    align-items: flex-start;
    align-items: center; /* 垂直居中 */
  }
  
  .left {
    flex: 0 0 30%; /* 图片部分占用 20% 的宽度 */
  margin-right: 20px;
  }
  
  .right {
    flex: 1; /* 剩余部分占用 70% 宽度 */
  }

  .left img {
    width: 100%; /* 使图片占据 left 部分的 100% 宽度 */
  }
  
  h2 {
    font-size: 1em;
  }

  .pdf-link:hover {
    text-decoration: underline;
  }

  .button-container {
    display: flex;
    gap: 10px; /* 按钮之间的间距 */
  }
  
  .custom-button {
    display: inline-flex;
    font-size: 14px;
    font-weight: 600;
    color: #00000083;
    align-items: center; /* 图标和文字垂直居中对齐 */
    justify-content: center; /* 图标和文字水平居中对齐 */
    padding:5px 10px;
    border-radius: 5px; /* 按钮的圆角效果 */
     background-image: linear-gradient(
   
        #f4f7c713,
        #f4f7c737,
        #ffeac346,
        #ffdecf53,
        #ffd8e344,
        #fdd7f649
  );
    text-decoration: none; /* 去掉文字下划线 */
    transition: background-color 0.3s ease; /* 背景颜色渐变效果 */
  }
  
  .custom-button:hover {
    background-color: #eaeaea; /* 悬停时的背景颜色变化 */
  }
  
  .icon {
    margin-right: 8px; /* 图标与文字之间的间距 */
    font-size: 18px; /* 调整图标大小 */
  }

  .paper-award {
    color:rgba(238, 178, 14, 0.798);
    font-size:14px;
    font-weight:600;
    padding: 5px 10px;
  }
  
  
/* 当屏幕宽度小于 768px 时，图片和文字竖直排列 */
@media (max-width: 768px) {
    .each-research {
      flex-direction: column; /* 垂直排列，图片在上，文字在下 */
    }
  
    .left {
      flex: 0 0 auto;
      margin-right: 0;
      margin-bottom: 20px; /* 图片和文字之间的间距 */
    }
  
    .right {
      flex: 0 0 auto;
    }
  }