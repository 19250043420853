.tooltip {
    display: inline-block;
    position: relative;
    border-bottom: 1px dotted #666;
    text-align: left;
}

.tooltip .top {
 width: 200px;
    top: -20px;
    left: 50%;
    transform: translate(-30%, -100%);
    padding: 10px;
    background-color:rgba(255, 133, 182, 0.177);
    font-weight: normal;
    font-size: 14px;
    border-radius: 8px;
    position: absolute;
    z-index: 99999999;
    box-sizing: border-box;
    display: none;
}

.tooltip:hover .top {
    display: block;
}

.tooltip .top i {
    position: absolute;
    top: 100%;
    left: 30%;
    margin-left: -15px;
    width: 30px;
    height: 15px;
    overflow: hidden;
}

.tooltip .top i::after {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    background-color:rgba(255, 133, 182, 0.177);
}