@import 'general';

#projects-container {
    background-color: var(--background-light);
}

.projects-item {
    float: left;
    margin: 5px;
    width: 260px;
    height: 180px;
    position: relative;
    overflow: hidden;
    border-radius: 5%;
    z-index: 102;
}

.projects-item img {
   margin-left:0%;
   object-fit: cover;
   width: 100%;
   height: 100%;
   transition: transform .2s ease;
   z-index: 101;
}

.project-caption {
    position:absolute;
    bottom: 0;
    width:100%;
    background-color: rgba(0, 0, 0, .5);
}

.project-caption p{
    margin-left: 5%;
    color: white;
    line-height: 10px;
}

.project-caption b {
    color: white;
}

.description {
    font-size: 12px;
}

.projects-item:hover img {
    transform: scale(1.1);
    transition: transform .5s ease;
    
}

.update {
    margin-bottom: 12px;
}


.break {
    flex-basis: 100%;
    height: 0;
    clear: both;
}


.category {
    display: block;
    margin:20px 0px 20px 0px;
    font-weight: 600;
}

.typewriter {
   
    padding: 0px 0px 20px 0px;
    opacity: 0.5;
}

.writertext {
    
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.smallyear {
    color:white;
    font-size: 12px;
    line-height: 0px;
}


@-webkit-keyframes SHOW-BOX {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes SHOW-BOX {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes SHOW-BOX {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes SHOW-BOX {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

#business-box {
    -webkit-animation: SHOW-BOX 1s ease;
        -moz-animation: SHOW-BOX 1s ease;
        -o-animation: SHOW-BOX 1s ease;
        animation: SHOW-BOX 1s ease;
        display: block;

}

#business-category {
    cursor: pointer;
}