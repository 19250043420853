@import './navbar.css';
@import './general.css';
@import './about.css';
@import './education.css';
@import './news.css';
@import './projects.css';
@import './fun.css';
@import './research.css';

div.page-container {
    margin: 0;
}

body {
    margin: 0;
}