@import 'general';

.edu-part {
    display: flex;
    width: 70%;
}

.intern-part {
    display: flex;
    width: 30%;
}

.time {
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    color: #00000083;
}

li{
    margin-left: -10px;
}

#edu-container {
    background-color: white;
}

.edu-logo {
    height: 80px;
}

.edu-box {
    height:80px;
}

.edu-logobox {
    width: 90px;
    float: left;
    margin-right: 15px;
    visibility: visible;
    opacity: 0.9;
    display: block;
    filter: grayscale(0.8);
    transition: 0.2s;
}

.edu-logobox:hover{
    opacity: 1;
    transition: 0.2s;
    filter: grayscale(0.2);
}
