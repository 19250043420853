@import 'general';

#news-container {
    background-color: var(--background-light);
}

#awards-container {
    background-color: var(--background-light);
}

.scrollable-content {
    max-height: 200px; /* Set the maximum height before scrolling starts */
    overflow-y: auto; /* Make the content scrollable */
  }