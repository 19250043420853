@import "general";

nav {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  background-color: white;
  z-index: 200;
  padding-top: 10px;
  padding-left: 4%;
}

.menu {
  margin-top: 20px;
  display: flex;
}
a {
  transition: background-size 400ms;
}
.menu a {
  line-height: 100%;
  cursor: pointer;
  margin-left: 20px;
  font-size: 16px;
  text-decoration: none;
  font-weight: 600;
  background: linear-gradient(
      to right,
      rgba(222, 222, 222, 0),
      rgba(222, 222, 222, 0)
    ),
    linear-gradient(
      to right,
      rgba(252, 238, 161, 0.494),
      rgba(255, 133, 182, 0.502),
      rgba(232, 102, 167, 0.546)
    );
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
}

a:hover {
  background-size: 0 3px, 100% 3px;
}

.footer-container {
  width: 100%;
  height: 50px;
  background-image: radial-gradient(
    circle,
    #fdd7f649,
    #ffd8e344,
    #ffdecf53,
    #ffeac346,
    #f4f7c737,
    #f4f7c713
  );
  position:relative;
  bottom: 0;
  text-align: center;
  line-height: 50px;
}

.namelogo {
  height: 60%;
  display: flex;
  float: left;
  margin-top: 10px;
  opacity: 0.9;
}
